import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const isBrowser = typeof window !== "undefined"

export default function IndexPage() {
  React.useEffect(() => {
    if (isBrowser) {
      setTimeout(function () {
        window.location.replace("https://thebaconhospital.com")
      }, 5000)
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <div className="min-h-screen flex flex-col items-start justify-start p-8 md:p-24">
        <p className="text-2xl pb-4">Sustainable Studio is now a part of</p>
        <Link to="https://thebaconhospital.com">
          <h1 className="text-left text-6xl md:text-7xl font-bold uppercase text-primary">
            The Bacon Hospital
          </h1>
        </Link>
      </div>
    </Layout>
  )
}
